<template>
    <Modal class="file-edit-form" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('cms.edit_file_properties')}}
        </template>
        
        <template v-slot:body>
            <BaseForm 
                :form_class="'form_small'" 
                @closeForm="closeForm()" 
                :cancelButton="''"
                :deleteButton="true" >
            </BaseForm>
        </template>

    </Modal>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm.vue'
import Modal from "@common/components/Modal";
import useFormData from '@common/composable/BaseFormData';
import { useI18n } from 'vue-i18n';
import { watchEffect } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "FileEditForm",
    components: {
        BaseForm,
        Modal
    },
    emits: ['close'],
    props: {
        opened: Boolean,
        baseData: {
            type: Object,
            default: () => null
        },
    },    
    setup(props,context){
        const i18n = useI18n();          
        const { data,setRows,store_action,formInit,formActions,code } = useFormData();
        formInit();
        
        const rows = [
            {
                labels: [i18n.t('cms.filename')],
                items: [
                    {
                        title: i18n.t('cms.filename'),
                        type: 'text',
                        key: "title",
                        class: "w-xl",
                    }
                ]
            },
            // {
            //     labels: [i18n.t('cms.alt')],
            //     items: [
            //         {
            //             label: i18n.t('cms.alt_explanation'),
            //             title: i18n.t('cms.alt'),
            //             type: 'text',
            //             key: "alt",
            //             class: "w-xl",
            //         }
            //     ]
            // },
            {
                labels: [i18n.t('cms.tags')],
                items: [
                    {
                        label: i18n.t('cms.tags_explanation'),
                        title: i18n.t('cms.tags'),
                        type: 'text',
                        key: "tags",
                        class: "w-xl",
                    }
                ]
            },
            {
                labels: [i18n.t('cms.file_type')],
                items: [
                    {
                        title: i18n.t('cms.file_type'),
                        type: 'info',
                        key: "type",
                        class: "w-xl",
                    }
                ]
            },
            {
                labels: [i18n.t('cms.size')],
                items: [
                    {
                        title: i18n.t('cms.size'),
                        type: 'label',
                        
                        class: "w-xl",
                        content: () => {
                            let size = data.value.size/1024;
                            return size.toFixed(1) + ' kB';
                        }
                    }
                ]
            },
        ];

        const store = useStore();
        
         const closeForm = () => {
            data.value = {};
            context.emit('close');
        }


        watchEffect(() => {
            if(props.baseData === null){
                return;
            }
            data.value = Object.assign({},props.baseData);
            code.value = props.baseData.code;
            store_action.value = 'file/updateItem';
            setRows(rows);
            formActions.value.afterSubmit = () => closeForm();
                
            formActions.value.cancelForm = () =>  closeForm();
            formActions.value.deleteItem = () => {
                store.dispatch('file/deleteItem',{code: code.value});
                closeForm();
            };
        });


        
        
        return {
            closeForm,
            data,
        }
    }
}
</script>

<style scoped>

.file-edit-form{
    z-index: 2100;
}

</style>